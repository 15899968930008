<template>
  <section>
    <div id="calendar__container">
      <v-progress-circular indeterminate color="primary" v-if="loading"/>
      <calendar-desktop ref="calendarDesktop" v-else-if="calendarDesktopDisplayed"/>
      <calendar-mobile ref="calendarMobile" v-else-if="calendarMobileDisplayed"/>

      <confirm-dialog ref="confirmDialog"
                      v-model="dialog.display"
                      :title="$t('generalCalendar.create.dialog.title', { user: user.username })"
                      :loading="dialog.loading"
                      :right-button-text="$t('general.buttons.create')"
                      :persistent="true"
                      @cancel="$router.go(-1)"
                      @valid="onCreateCalendarUser"
      >
        <template v-slot:body>
          {{ $t('generalCalendar.create.dialog.text', { user: user.username }) }}
          <v-dialog ref="dialog"
                    v-model="dialog.pickerDisplay"
                    :return-value.sync="dialog.date"
                    persistent
                    width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field :value="localeDate"
                            :label="$t('generalCalendar.create.dialog.input')"
                            :error-messages="dialog.errorMessage"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
              />
            </template>
            <v-date-picker v-model="dialog.date"
                           scrollable
                           :allowed-dates="allowedDates"
                           :first-day-of-week="1"
            >
              <v-spacer/>
              <v-btn text @click="dialog.pickerDisplay = false">{{ $t('general.buttons.cancel') }}</v-btn>
              <v-btn text
                     color="primary"
                     @click="onSelectDate"
              >
                {{ $t('general.buttons.validate') }}
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </template>
      </confirm-dialog>
    </div>
    <footer style="clear: both">
      <v-btn :to="{ name: 'DashboardPatient', params: { patientId: patientId } }" color="secondary" depressed>
        {{ $t('general.buttons.return') }}
      </v-btn>
    </footer>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'GeneralCalendar',

  components: {
    ConfirmDialog: () => import('@/components/Template/ConfirmDialog'),
    CalendarDesktop: () => import('@/components/GeneralCalendar/CalendarDesktop'),
    CalendarMobile: () => import('@/components/GeneralCalendar/CalendarMobile'),
  },

  props: {
    patientId: {
      required: true,
    },
  },

  data: () => ({
    dialog: {
      date: null,
      display: false,
      errorMessage: '',
      loading: false,
      pickerDisplay: false,
    },
    dialog2: {
      display: false,
      errorMessage: ''
    },
    loading: false,
    now: Date.now(),
    today: new Date().setHours(0,0,0,0),
  }),

  computed: {
    ...mapGetters([
      'calendar',
      'user',
    ]),
    calendarDesktopDisplayed () {
      return !this.loading && !this.dialog.display && !this.$vuetify.breakpoint.mobile
    },
    calendarMobileDisplayed () {
      return !this.loading && !this.dialog.display && this.$vuetify.breakpoint.mobile
    },
    localeDate () {
      return this.dialog.date === null
             ? ''
             : (new Date(this.dialog.date)).toLocaleDateString(
              navigator.language || 'fr-FR',
              {
                day: '2-digit',
                month: 'long',
                year: 'numeric',
              })
    },
  },

  created () {
    this.loading = true
    this.dialog.display = false

    Promise.all([
      this.loadUserCalendar(this.patientId),
      this.loadUser({ id: this.patientId }),
    ])
           .then(() => {
             this.dialog.display = this.calendar.length === 0

             if (!this.dialog.display) {
               // TODO: Mettre le numéro d'étude en dynamique et non en dure (a récupérer quand on pourra choisir dans la nav)
               this.loadMilestones(1)
                   .then(() => {
                     this.loading = false
                   })
             } else {
               this.loading = false
             }
           })
  },

  methods: {
    ...mapActions([
      'createCalendar',
      'loadMilestones',
      'loadUser',
      'loadUserCalendar',
      'updateUser',
    ]),
    allowedDates (date) {
      return (new Date(date).setHours(0,0,0,0)) >= this.today
    },
    onCreateCalendarUser () {
      if (this.dialog.date !== null && this.dialog.date !== '') {
        this.dialog.loading = true

        this.createCalendar({
          patientId: this.patientId,
          initDate: this.dialog.date,
        })
            .then(() => {
              this.loadMilestones(1)
                  .then(() => {
                    this.dialog.loading = false
                    this.dialog.display = false
                  })
            })
      } else {
        this.dialog.errorMessage = this.$t('generalCalendar.create.noDataError')
      }
    },
    onSelectDate () {
      this.$refs.dialog.save(this.dialog.date)
      this.dialog.errorMessage = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/style";

#calendar__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

section {
  footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    a {
      margin-top: 20px;
    }
  }
}

</style>
